import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Spinner, Carousel, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';
import { InstagramEmbed } from 'react-social-media-embed';
import { getInstaIDs } from '../../firebase/functions';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

function InstaWall() {
  const screenSize = window.innerWidth;
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const instaData = [
    'https://www.instagram.com/p/DARLydFs99F/?igsh=Zmd5ajZ3a3N3cXE0',
    'https://www.instagram.com/p/DAS-6zku_Rk/?igsh=YjdoMWd0M3d6ZWxr',
    'https://www.instagram.com/p/DATcLgrI66U/?igsh=azRqZTc5OTM4a3lr',
  ];
  const [instastatic, setInstastatic] = useState([]);
  const [loading, setLoading] = useState(true);
  const [display, setDisplay] = useState(true);
  const [width, setWidth] = useState(600);
  const sliderRef = useRef(null);

  useEffect(() => {
    const getData = async () => {
      const res = await getInstaIDs();
      if (res.length > 0) {
        setInstastatic(res.map((o) => o.insta_url_1));
      }
    };
    getData();
  }, []);

  const carouselConfig = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: screenSize > 900 ? 4 : 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        background: 'linear-gradient(45deg, #f58529, #dd2a7b, #8134af, #515bd4)',
        width: '100%',
        backdropFilter: 'blur(5px)',
        paddingBottom: { xs: '30px', sm: '30px', md: '10px' },
      }}
    >
      <div
        className="hashtag-div  "
        style={{
          paddingTop: '2%',
        }}
      >
        <Typography
          variant="h1"
          sx={{ color: '#3939A0', fontWeight: '700', textAlign: 'center', color: 'white' }}
        >
          {t('instagram_media_feed')}
        </Typography>
        <Container style={{ justifyContent: 'center', display: 'flex', marginTop: '3%' }}>
          <Row style={{ margin: '2%' }}>
            <Col
              xs={12}
              md={6}
              lg={2}
              className="target_units_sub text-center"
              style={{ color: 'white', fontSize: '20px' }}
            >
              #SHS2024
            </Col>
            <Col
              xs={12}
              md={6}
              lg={4}
              className="target_units_sub text-center"
              style={{ color: 'white', fontSize: '20px' }}
            >
              #SwachhataHiSeva2024
            </Col>
            <Col
              xs={12}
              md={6}
              lg={3}
              className="target_units_sub text-center"
              style={{ color: 'white', fontSize: '20px' }}
            >
              #SwabhavSwachhata
            </Col>
            <Col
              xs={12}
              md={6}
              lg={3}
              className="target_units_sub text-center"
              style={{ color: 'white', fontSize: '20px' }}
            >
              #SanskaarSwachhata
            </Col>
          </Row>
        </Container>
      </div>

      <Container
        style={{
          marginBottom: '2%',
          display: screenSize > 768 && 'flex',
          justifyContent: 'center',
          zIndex: 22,
        }}
      >
        <Container
          style={{
            marginBottom: '2%',
            // backgroundColor: 'white',
            padding: '2px',
          }}
        >
          <Slider
            {...{
              ...carouselConfig,
              infinite: true,
              responsive: [
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 576,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ],
            }}
          >
            {instastatic.map((item) => (
              <Col
                xl={4}
                lg={4}
                md={6}
                sm={12}
                key={item}
                className="hashtagImg_sm d-flex justify-content-center"
                style={{ margin: '10px 80px' }}
              >
                <InstagramEmbed url={item} width={320} style={{ margin: '2px' }} />
              </Col>
            ))}
          </Slider>
        </Container>
      </Container>
    </Box>
  );
}

export default InstaWall;
