import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full-layout/loadable/Loadable';
import EventType from '../views/event-type';
import HomeAfter from '../views/homeafter';
import Partners from '../views/partners';
import { element } from 'prop-types';
import CTUMapContainer from '../views/events-map/ctu';
import BeforeAfterGallery from '../views/beforeAfterPage';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full-layout/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank-layout/BlankLayout')));
/* ***End Layouts**** */

const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));
const ResetPassword = Loadable(lazy(() => import('../views/authentication/ResetPassword')));

/* ****Pages***** */
const Dashboard = Loadable(lazy(() => import('../views/dashboard')));
const Events = Loadable(lazy(() => import('../views/events')));
const Permissions = Loadable(lazy(() => import('../views/permissions')));
const Roles = Loadable(lazy(() => import('../views/roles')));
const Users = Loadable(lazy(() => import('../views/users')));
const AddEvent = Loadable(lazy(() => import('../views/events/add-event')));
const PublicEvent = Loadable(lazy(() => import('../views/public-event')));
const MapContainer = Loadable(lazy(() => import('../views/events-map')));
const T1MapContainer = Loadable(lazy(() => import('../views/events-map/theme1')));
const T2MapContainer = Loadable(lazy(() => import('../views/events-map/theme2')));
const T3MapContainer = Loadable(lazy(() => import('../views/events-map/theme3')));
const Home = Loadable(lazy(() => import('../views/home')));
const CloseEvent = Loadable(lazy(() => import('../views/CloseEvent')));
const EventList = Loadable(lazy(() => import('../views/events/event-list')));
const EventPage = Loadable(lazy(() => import('../views/events/event-page')));
const EditEvent = Loadable(lazy(() => import('../views/events/edit-event')));
const UpdateDailyEvent = Loadable(lazy(() => import('../views/events/update-daily-event')));
const BlackSpotIdentification = Loadable(
  lazy(() => import('../views/black-spots/black-spot-identification')),
);
const BlackSpotList = Loadable(lazy(() => import('../views/black-spots/black-spot-list')));
const ApproveBlackSpot = Loadable(lazy(() => import('../views/black-spots/approve-black-spot')));
const BlackSpots = Loadable(lazy(() => import('../views/black-spots')));
const PartnerSignUp = Loadable(lazy(() => import('../views/authentication/PartnerSignup')));
const AddUser = Loadable(lazy(() => import('../views/users/add-user')));
const Media = Loadable(lazy(() => import('../views/media')));
const ViewEvent = Loadable(lazy(() => import('../views/events/view-event')));
const PartnersList = Loadable(lazy(() => import('../views/partnersList')));
const ApproveEvent = Loadable(lazy(() => import('../views/approve-event')));
const ApprovePartner = Loadable(lazy(() => import('../views/approve-partner')));
const CreateSBM = Loadable(lazy(() => import('../views/create-sbm')));
const Reports = Loadable(lazy(() => import('../views/reports')));
const Downloads = Loadable(lazy(() => import('../views/downloads')));
const Sop = Loadable(lazy(() => import('../views/sop')));
const TrainingSchedule = Loadable(lazy(() => import('../views/training-schedule')));
const ViewSBM = Loadable(lazy(() => import('../views/view-sbm')));
const CitizenTestinmonials = Loadable(lazy(() => import('../views/citizen-testimonials')));
const CitizenTestinmonialsList = Loadable(lazy(() => import('../views/citizen-testimonials-list')));
const EventClosureReport = Loadable(lazy(() => import('../views/event-closure-report')));
const AdoptCtuList = Loadable(lazy(() => import('../views/adoptCtuList')));
const Citizen = Loadable(lazy(() => import('../views/citizen-corner')));
const MyEvents = Loadable(lazy(() => import('../views/myEvents')));
const CampaignDashboard = Loadable(lazy(() => import('../views/campaignDashboard')));
const PartnerReport = Loadable(lazy(() => import('../views/partnerReport')));

/* ****Routes***** */

const routes = [
  // { path: '/after-15', element: <Home /> },
  { path: '/', element: <HomeAfter /> },
  { path: '/events-map', element: <MapContainer /> },
  { path: '/ctu-map', element: <CTUMapContainer /> },
  { path: '/theme1-map', element: <T1MapContainer /> },
  { path: '/theme2-map', element: <T2MapContainer /> },
  { path: '/theme3-map', element: <T3MapContainer /> },
  { path: '/before-after-ctu-gallery', element: <BeforeAfterGallery /> },
  { path: '/public-events/:id', element: <PublicEvent /> },
  { path: '/event-type/:id', element: <EventType /> },
  { path: '/event/:id', element: <EventPage /> },
  { path: 'partner-signup', element: <PartnerSignUp /> },
  { path: '/public-media', element: <Media /> },
  { path: 'downloads', element: <Downloads /> },
  { path: 'campaign-dashboard', element: <CampaignDashboard /> },
  // { path: 'sop', element: <Sop /> },
  { path: 'partners', element: <Partners /> },
  { path: 'citizen', element: <Citizen /> },
  // { path: 'training-schedule', element: <TrainingSchedule /> },
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'partners-list', element: <PartnersList /> },
      { path: 'create-sbm', element: <CreateSBM /> },
      { path: 'view-sbm', element: <ViewSBM /> },
      { path: 'view-citizen-testimonials', element: <CitizenTestinmonialsList /> },
      { path: 'citizen-testimonials', element: <CitizenTestinmonials /> },
      { path: 'add-event', element: <AddEvent /> },
      { path: 'add-user', element: <AddUser /> },
      { path: 'reports', element: <Reports /> },
      { path: 'event-closure-report', element: <EventClosureReport /> },
      { path: 'approve-event', element: <ApproveEvent /> },
      { path: 'approve-partner', element: <ApprovePartner /> },
      { path: 'adopt-ctu-list', element: <AdoptCtuList /> },
      { path: 'partner-report', element: <PartnerReport /> },
      { path: 'my-events', element: <MyEvents /> },
      {
        path: 'black-spots',
        element: <BlackSpots />,
        children: [
          { path: '', element: <Navigate to="black-spot-list" /> },
          { path: 'black-spot-list', element: <BlackSpotList /> },
          { path: 'black-spot-identification', element: <BlackSpotIdentification /> },
        ],
      },
      {
        path: 'events',
        element: <Events />,
        children: [
          { path: '', element: <Navigate to="event-list" /> },
          { path: 'event-list', element: <EventList /> },
          { path: 'close-event/:id', element: <CloseEvent /> },
          { path: 'edit-event/:id', element: <EditEvent /> },
          { path: 'view-event/:id', element: <ViewEvent /> },
          { path: 'update-daily-event/:id', element: <UpdateDailyEvent /> },
          { path: 'event/:id', element: <EventPage /> },
        ],
      },
      { path: 'permissions', element: <Permissions /> },
      { path: 'approve-black-spot', element: <ApproveBlackSpot /> },
      { path: 'roles', element: <Roles /> },
      { path: 'users', element: <Users /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: 'auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: 'login', element: <Login /> },
      { path: 'reset-password', element: <ResetPassword /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default routes;
