import axios from 'axios';
import { getAllEventTypes, getCurrentToken } from '../firebase/functions';

const BASE_URL = 'https://asia-south1-dashakswachhataka.cloudfunctions.net/';

axios.defaults.baseURL = BASE_URL;
const encodedURIcomponent = (url) => encodeURIComponent(url);

export const resetPassword = async (payload) => {
  try {
    return await axios.post(`updatePassword`, payload);
  } catch (err) {
    console.log(err);
  }
};

export const getJanBhagidariData = async () => {
  try {
    return await axios.get(`getDashboardJanBhagidaariData`);
  } catch (err) {
    console.log(err);
  }
}

export const getCampaignCardData = async () => {
  try {
    return await axios.get(`getPublicDashboardLatestView`);
  } catch (err) {
    console.log(err);
  }
};

export const updatePermissions = async (payload) => {
  try {
    return await axios.post('addAndUpdatePermission', payload);
  } catch (err) {
    console.log(err);
  }
};

export const addRole = async (payload) => {
  try {
    return await axios.post('addRole', payload);
  } catch (err) {
    console.log(err);
  }
};

export const createUser = async (payload) => {
  const token = await getCurrentToken();
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    return await axios.post('userCreation', payload, { headers });
  } catch (err) {
    console.log(err);
  }
};

export const createCityReport = async (payload) => {
  const token = await getCurrentToken();
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    return await axios.post('createCityReport', payload, { headers });
  } catch (err) {
    console.log(err);
  }
};

export const addEvent = async (payload) => {
  const token = await getCurrentToken();
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    return await axios.post('createEvent-V1', payload, { headers });
    // return await axios.post('createEvent', payload, { headers });
  } catch (err) {
    console.log(err);
  }
};

export const updateEvent = async (payload) => {
  const token = await getCurrentToken();
  try {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    return await axios.post('updateEvent', payload, { headers });
  } catch (err) {
    console.log(err);
  }
};

export const getPresignedUrl = async (filename, foldername) => {
  try {
    return await axios.post(`getSignedUrl?folder=${foldername}&name=${filename}&action=write`);
  } catch (err) {
    console.log(err);
  }
};

export const getPresignedUrlRead = async (filename, foldername) => {
  try {
    return await axios.post(`getSignedUrl?folder=${foldername}&name=${filename}&action=read`);
  } catch (err) {
    console.log(err);
  }
};
export const createCityReportActivity = async (payload) => {
  try {
    return await axios.post('createCityReportActivity', payload);
  } catch (e) {
    console.log(e);
  }
};

export const createEventActivity = async (payload) => {
  const token = await getCurrentToken();
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
  try {
    return await axios.post('createMediaEvent', payload, { headers });
  } catch (e) {
    console.log(e);
  }
};

export const getSignedUrl = async (folder, name) => {
  try {
    return await axios.post(`getSignedUrl?folder=${folder}&name=${name}&action=read`);
  } catch (e) {
    console.log(e);
  }
};

export const eventClosure = async (payload) => {
  const token = await getCurrentToken();
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
  try {
    return await axios.post('updateEvent', payload, { headers });
  } catch (e) {
    console.log(e);
  }
};

// export const getEventDetailedReport = async (payload) => {
//   const token = await getCurrentToken();
//   const headers = {
//     'Content-Type': 'application/json',
//     Authorization: `Bearer ${token}`,
//   };
//   try {
//     return await axios.get('https://asia-south1-dashakswachhataka.cloudfunctions.net/exportCumulativeReportEventNameStateWise', payload, { headers });
//   } catch (e) {
//     console.log(e);
//   }
// };

export const getEventDetailedReport = async (payload) => {
  const token = await getCurrentToken();
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  // Convert the payload to query string parameters
  const queryParams = new URLSearchParams(payload).toString();

  try {
    return await axios.get(
      `https://asia-south1-dashakswachhataka.cloudfunctions.net/exportCumulativeReportEventNameStateWise-v1c?${queryParams}`,
      { headers },
    );
  } catch (e) {
    console.log(e);
  }
};

export const getOtp = async (payload) => {
  try {
    return await axios.post('getOtp/sendOTP', payload);
  } catch (e) {
    console.log(e);
  }
};

export const verifyOtp = async (payload) => {
  try {
    return await axios.post(`getOtp/verifyOTP`, payload);
  } catch (e) {
    return e.response;
  }
};

export const userNameVerifyOtp = async (payload) => {
  try {
    return await axios.post(`resetPasswordForUserName/verifyOTP`, payload);
  } catch (e) {
    return e.response;
  }
};

export const userNamePasswordGetOtp = async (payload) => {
  try {
    return await axios.post(`resetPasswordForUserName/sendOTP`, payload);
  } catch (e) {
    return e.response;
  }
};

export const createPartner = async (data) => {
  return await axios.post(`partnerUserCreation`, data);
};

export const getDiwasCard = async () => {
  try {
    return await axios.get(
      `getCachedResult?url=${encodedURIcomponent(BASE_URL + `getLandingPageSBDData`)}`,
    );
  } catch (err) {
    console.log(err);
  }
}

export const updatePartner = async (user_id) => {
  return await axios.post('partnerUserApproval', { user_id });
};

export const partnerJoinAndAdaptCTU = async (data) => {
  console.log(data)
  return await axios.post('partnerUserJoinAndAdaptCTUV1', data);
};

export const approvePartnerJoinAndAdoptCTU = async (data) => {
  return await axios.post('partnerEventAndCTUApprovalV1', data);
};

export const createSBM = async (data) => {
  return await axios.post('createSBMV1', data);
  // return await axios.post('createSBM', data);
};

export const createCitizenTestimonial = async (data) => {
  return await axios.post('createCitizenTestimonials', data);
};

// export const getEventCardDetails = async () => {
//   try {
//     const res = await getAllEventTypes();
//     const promises = res.map((item) =>
//       axios.post(`getMediaCount?event_type_id=${item?.event_type_id}`).then((response) => ({
//         event_type_id: item?.event_type_id,
//         event_name: item?.event_type_name,
//         theme: item?.theme,
//         event_data: response.data,
//       })),
//     );

//     const results = await Promise.all(promises);
//     return results; // return the collected results
//   } catch (e) {
//     console.log(e);
//   }
// };

export const getStateCount = async () => {
  try {
    return await axios.post(
      `getCachedResultV1?url=${encodedURIcomponent(
        BASE_URL +
          'getReportsLatest?resource=events&fields=["*","state_code"]&clickhouse_fields=["*","state_code"]&labels=["event_count"]&modifiers=["count"]&rank_by=event_count&categories=["state_code"]&sort=DESC&max_results=100&filters=[]',
      )}`,
    );
  } catch (e) {
    console.log(e);
  }
};

export const getCTUCount = async () => {
  try {
    return await axios.post(
      `getCachedResultV1?url=${encodedURIcomponent(
        BASE_URL +
          'getReportsLatest?resource=events&fields=["*","state_code"]&clickhouse_fields=["*","state_code"]&labels=["event_count"]&modifiers=["count"]&rank_by=event_count&categories=["state_code"]&sort=DESC&max_results=100&filters=["event_heading_name=\'CTU Identification\'"]',
      )}`,
    );
  } catch (e) {
    console.log(e);
  }
};

export const getTopCtuIdentifiedState = async () => {
  try {
    return await axios.post(
      `getReports?table_name=events&column_names=["event_id","state_code","state_name"]&aggregation_types=[ "count"]&group_by=["state_code","state_name"]&sort=DESC&limit=5&where_clauses=["event_heading_name='CTU Identification'"]&order=event_id`,
    );
  } catch (e) {
    console.log(e);
  }
};

export const getTopCtuIdentifiedArea = async () => {
  try {
    return await axios.post(
      `getReportsLatest?resource=events&fields=["*","area_type"]&clickhouse_fields=["*","area_type"]&labels=["event_count"]&modifiers=["count"]&rank_by=event_count&categories=["area_type"]&sort=DESC&max_results=4&filters=["event_heading_name=\'CTU Identification\'"]`,
    );
  } catch (e) {
    console.log(e);
  }
};

export const getTopGarbageCleaned = async () => {
  try {
    return await axios.post(
      `getCachedResultV1?url=${encodedURIcomponent(
        BASE_URL +
          'getReportsLatest?resource=event_media&fields=["state_code","approx_garbage_amount","state_name"]&clickhouse_fields=["state_code","approx_garbage_amount","state_name"]&labels=["","total_garbage_collected",""]&modifiers=["","sum",""]&rank_by=total_garbage_collected&categories=["state_code","state_name"]&sort=DESC&max_results=5&filters=["event_heading_name=\'CTU Identification\'"]',
      )}`,
    );
  } catch (e) {
    console.log(e);
  }
};

export const getMediaImages = async (page, limit) => {
  try {
    return await axios.get(
      `getCachedResult?url=${encodedURIcomponent(
        BASE_URL +
          `getLandingPageMedia?collection_name=moderated_event_media&page=${page}&limit=${limit}&moderated=true&moderation_accepted=true`,
      )}`,
    );
  } catch (e) {
    console.log(e);
  }
};

export const getEventMediaImages = async (page, limit, event_id) => {
  try {
    return await axios.get(
      BASE_URL +
        `getLandingPageMedia?collection_name=moderated_event_media&page=${page}&limit=${limit}&moderated=true&moderation_accepted=true&event_id=${event_id}`,
    );
  } catch (e) {
    console.log(e);
  }
};

export const getPublicMediaImages = async (page, limit, event_theme_id) => {
  try {
    return await axios.get(
      BASE_URL +
        `getLandingPageMedia?collection_name=moderated_event_media&page=${page}&limit=${limit}&event_theme_id=${event_theme_id}&moderated=true&moderation_accepted=true`,
    );
  } catch (e) {
    console.log(e);
  }
};
// export const getSignedUrlLightBox = async (folder, name) => {
//   try {
//     return await axios.get(`getSignedUrl?folder=${folder}&name=${name}&action=read`);
//   } catch (e) {
//     console.log(e);
//   }
// };
export const getCtuDateRange = async () => {
  try {
    return await axios.post(
      `getCachedResultV1?url=${encodedURIcomponent(
        BASE_URL +
          'getReportsLatest?resource=events&fields=["*"]&clickhouse_fields=["*"]&labels=["count"]&modifiers=["count"]&categories=[]&max_results=100&sort=ASC&rank_by=date_range&filters=["event_heading_name=\'CTU Identification\'","created_at BETWEEN \'2024-09-05\' AND \'2024-10-05\'"]&date_ranges=[{"column":"created_at","start":"2024-09-05","end":"2024-09-07","label":"5 to 7"},{"column":"created_at","start":"2024-09-07","end":"2024-09-09","label":"7 to 9"},{"column":"created_at","start":"2024-09-09","end":"2024-09-11","label":"9 to 11"},{"column":"created_at","start":"2024-09-11","end":"2024-09-13","label":"11 to 13"},{"column":"created_at","start":"2024-09-13","end":"2024-09-15","label":"13 to 15"}]',
      )}`,
    );
  } catch (e) {
    console.log(e);
  }
};

export const getReportsData = async (filters) => {
  console.log(filters)
  try {
    return await axios.post(
      `getCachedResultV1?url=${encodedURIcomponent(
        BASE_URL +
          `exportReportV1?resource=${filters.resource}&fields=${filters.fields}&clickhouse_fields=${filters.fields}&rank_by=${filters.rank_by}&filters=${filters.filters}`,
      )}`,
    );
  } catch (e) {
    console.log(e);
  }
};

export const getLandingPageData = async () => {
  try {
    return await axios.post(
      `getCachedResult?url=${encodedURIcomponent(BASE_URL + 'getLandingPageReports')}`,
    );
  } catch (e) {
    console.log(e);
  }
};

export const getLandingPagePostData1 = async () => {
  try {
    return await axios.post(
      `getCachedResult?url=${encodedURIcomponent(BASE_URL + 'getDashboardCtuDataAndOtherV1')}`,
    );
  } catch (e) {
    console.log(e);
  }
};

export const getLandingPagePostData2 = async () => {
  try {
    return await axios.post(
      `getCachedResult?url=${encodedURIcomponent(BASE_URL + 'getLandingPageCtuDataAndOtherV1')}`,
    );
  } catch (e) {
    console.log(e);
  }
};

export const getLandingPagePostData3 = async () => {
  try {
    return await axios.post(
      `getCachedResult?url=${encodedURIcomponent(BASE_URL + 'getLandingPageDataLatestCampaignV1')}`,
    );
  } catch (e) {
    console.log(e);
  }
};

export const getVisitorCount = async () => {
  try {
    return await axios.post(
      `getCachedResult?url=${encodedURIcomponent(BASE_URL + 'getVisitorCount')}`,
    );
  } catch (e) {
    console.log(e);
  }
};

export const getReportsALL = async (type) => {
  try {
    return await axios.get(`exportEventAndUserData?type=${type}`);
  } catch (e) {
    console.log(e);
  }
};

export const getCumilativeReports = async (query) => {
  try {
    const token = await getCurrentToken();
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    return await axios.get(`exportReport?${query}`, {}, { headers });
  } catch (e) {
    console.log(e);
  }
};

export const getSbmAndCitizenReportData = async (query) => {
  try {
    const token = await getCurrentToken();
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    return await axios.get(`getSbmAndCitizenReportData?${query}`, {}, { headers });
  } catch (e) {
    console.log(e);
  }
};

export const getReports = async () => {
  try {
    return await axios.post(
      `getCachedResultV1?url=${encodedURIcomponent(
        BASE_URL +
          `/getReportsLatest?resource=events&fields=["*","event_name_id","event_name"]&clickhouse_fields=["*","event_name_id","event_name"]&labels=["event_count"]&modifiers=["count"]&rank_by=event_count&categories=["event_name_id","event_name"]`,
      )}`,
    );
  } catch (e) {
    console.log(e);
  }
};

export const getEventThemeCount = async () => {
  try {
    return await axios.post(
      `getCachedResultV1?url=${encodedURIcomponent(
        BASE_URL +
          `/getReportsLatest?resource=events&fields=["*","event_theme_id"]&clickhouse_fields=["*","event_theme_id"]&labels=["event_count"]&modifiers=["count"]&rank_by=event_count&categories=["event_theme_id"]`,
      )}`,
    );
  } catch (e) {
    console.log(e);
  }
};

export const getEventDetails = async (event_id) => {
  try {
    return await axios.post(`getEvent?event_id=${event_id}`);
  } catch (e) {
    console.log(e);
  }
};

export const getPartnerReport = async (payload) => {
  try {
    return await axios.get('getPartnerCtuAdopJoinReport', {
      params: payload,
    });
  } catch (e) {
    console.log(e);
  }
};

export const publicEvents = async () => {
  const headers = {
    'Content-Type': 'application/json',
  };
  try {
    return await axios.post('createMediaEventV1', payload, { headers });
  } catch (e) {
    console.log(e);
  }
};

export const getCampaignDashboardData = async (payload) => {
  return axios.get('getPublicLandingPageDataV2', {
    params: payload,
  });
};
export const fetchBeforeAfterImages = async (page, limit) => {
  try {
    return await axios.get(
      BASE_URL + `getEventMediaBeforeAndAfterImages?page=${page}&limit=${limit}`,
    );
  } catch (e) {
    console.log(e);
  }
  
  // export const getDiwasCardData = async () => {
  //   return axios.get( )
  // }
};
