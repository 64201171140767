import { Button } from '@mui/material';
import React from 'react';

const GoogleMapButton = ({ targetLat, targetLng }) => {
  const openGoogleMaps = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${latitude},${longitude}&destination=${targetLat},${targetLng}`;
          window.open(googleMapsUrl, '_blank');
        },
        (error) => {
          console.error('Error fetching location', error);
          // Handle error (user denied location, etc.)
          alert('Unable to retrieve your location.');
        },
      );
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  };

  return (
    <Button
      sx={{
        backgroundColor: '#f8f9fa',
        color: '#000',
        '&:hover': {
          backgroundColor: '#f8f9fa',
        },
      }}
      variant="outlined"
      onClick={openGoogleMaps}
    >
      <img src="https://img.icons8.com/color/48/000000/google-maps-new.png" alt="Google Maps" />
      Navigate to Destination
    </Button>
  );
};

export default GoogleMapButton;
