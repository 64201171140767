import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Card, CardContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getEventThemeCountById } from '../../firebase/functions';
import ThemeOneBg from '../../assets/images/backgrounds/theme_one_bg.png';
import ThemeTwoBg from '../../assets/images/backgrounds/theme_two_bg.png';
import ThemeThreeBg from '../../assets/images/backgrounds/theme_three_bg.png';
import ThemeFourBg from '../../assets/images/backgrounds/blue.png';
import { getEventThemeCount } from '../../api';
import TargetBg from '../../assets/images/backgrounds/targetbg.jpg';
import { Loader } from 'feather-icons-react/build/IconComponents';
import { formatIndianNumber } from '../../utils/helper';

export default function ThemeCount({
  campaignCtuData,
  campaignJanData,
  campaignMassData,
  campaignShivir,
}) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        width: '100%',
        margin: 'auto',
        textAlign: 'center',
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          minHeight: '60vh',
          paddingTop: '20px',
          marginBottom: '30px',
        }}
      >
        <Grid item xs={12}>
          <Typography className="main-heading">Campaign Pillars</Typography>
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0 4%',
          }}
        >
          {/* Card 4 */}
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                minHeight: '32vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'center',
                backgroundImage: `url(${ThemeFourBg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <CardContent>
                <Typography
                  className="my-2"
                  variant="h1"
                  fontWeight="bold"
                  fontSize="2rem"
                  color="white"
                >
                  Cleanliness Target Units
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="h2" fontWeight="bold" fontSize="1.2rem" color="white">
                      Event Created
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    {campaignCtuData?.event_created ? (
                      <Typography variant="h1" fontWeight="bold" fontSize="1.5rem" color="white">
                        {formatIndianNumber(campaignCtuData?.event_created)}
                      </Typography>
                    ) : (
                      <Loader size={20} color="#ffffff" />
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="h2" fontWeight="bold" fontSize="1.2rem" color="white">
                      Event Closed
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    {campaignCtuData?.event_closed ? (
                      <Typography variant="h1" fontWeight="bold" fontSize="1.5rem" color="white">
                        {formatIndianNumber(campaignCtuData?.event_closed)}
                      </Typography>
                    ) : (
                      <Loader size={20} color="#ffffff" />
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="h2" fontWeight="bold" fontSize="1.2rem" color="white">
                      Total Participation
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    {campaignCtuData?.total_participation ? (
                      <Typography variant="h1" fontWeight="bold" fontSize="1.5rem" color="white">
                        {formatIndianNumber(campaignCtuData?.total_participation)}
                      </Typography>
                    ) : (
                      <Loader size={20} color="#ffffff" />
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          {/* Card 1 */}
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                minHeight: '31vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'center',
                backgroundImage: `url(${ThemeOneBg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <CardContent>
                <Typography
                  className="my-2"
                  variant="h1"
                  fontWeight="bold"
                  fontSize="2rem"
                  color="white"
                >
                  Mass Cleanliness Drives
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="h2" fontWeight="bold" fontSize="1.2rem" color="white">
                      Event Created
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    {campaignMassData?.event_created ? (
                      <Typography variant="h1" fontWeight="bold" fontSize="1.5rem" color="white">
                        {formatIndianNumber(campaignMassData?.event_created)}
                      </Typography>
                    ) : (
                      <Loader size={20} color="#ffffff" />
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="h2" fontWeight="bold" fontSize="1.2rem" color="white">
                      Event Closed
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    {campaignMassData?.event_closed ? (
                      <Typography variant="h1" fontWeight="bold" fontSize="1.5rem" color="white">
                        {formatIndianNumber(campaignMassData?.event_closed)}
                      </Typography>
                    ) : (
                      <Loader size={20} color="#ffffff" />
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="h2" fontWeight="bold" fontSize="1.2rem" color="white">
                      Total Participation
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    {campaignMassData?.total_participation ? (
                      <Typography variant="h1" fontWeight="bold" fontSize="1.5rem" color="white">
                        {formatIndianNumber(campaignMassData?.total_participation)}
                      </Typography>
                    ) : (
                      <Loader size={20} color="#ffffff" />
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Card 2 */}
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                minHeight: '32vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'center',
                backgroundImage: `url(${ThemeTwoBg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <CardContent>
                <Typography
                  className="my-2"
                  variant="h1"
                  fontWeight="bold"
                  fontSize="2rem"
                  color="white"
                >
                  Swachhata Mein Jan Bhaagidari
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="h2" fontWeight="bold" fontSize="1.2rem" color="white">
                      Event Created
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    {campaignJanData?.event_created ? (
                      <Typography variant="h1" fontWeight="bold" fontSize="1.5rem" color="white">
                        {formatIndianNumber(campaignJanData?.event_created)}
                      </Typography>
                    ) : (
                      <Loader size={20} color="#ffffff" />
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="h2" fontWeight="bold" fontSize="1.2rem" color="white">
                      Event Closed
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    {campaignJanData?.event_closed ? (
                      <Typography variant="h1" fontWeight="bold" fontSize="1.5rem" color="white">
                        {formatIndianNumber(campaignJanData?.event_closed)}
                      </Typography>
                    ) : (
                      <Loader size={20} color="#ffffff" />
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="h2" fontWeight="bold" fontSize="1.2rem" color="white">
                      Total Participation
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    {campaignJanData?.total_participation ? (
                      <Typography variant="h1" fontWeight="bold" fontSize="1.5rem" color="white">
                        {formatIndianNumber(campaignJanData?.total_participation)}
                      </Typography>
                    ) : (
                      <Loader size={20} color="#ffffff" />
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Card 3 */}
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                minHeight: '32vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'center',
                backgroundImage: `url(${ThemeThreeBg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <CardContent>
                <Typography
                  variant="h1"
                  fontWeight="bold"
                  fontSize="2rem"
                  color="white"
                  className="my-2"
                >
                  SafaiMitra Suraksha Shivirs
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="h2" fontWeight="bold" fontSize="1.2rem" color="white">
                      Event Created
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    {campaignShivir?.event_created ? (
                      <Typography variant="h1" fontWeight="bold" fontSize="1.5rem" color="white">
                        {formatIndianNumber(campaignShivir?.event_created)}
                      </Typography>
                    ) : (
                      <Loader size={20} color="#ffffff" />
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="h2" fontWeight="bold" fontSize="1.2rem" color="white">
                      Event Closed
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    {campaignShivir?.event_closed ? (
                      <Typography variant="h1" fontWeight="bold" fontSize="1.5rem" color="white">
                        {formatIndianNumber(campaignShivir?.event_closed)}
                      </Typography>
                    ) : (
                      <Loader size={20} color="#ffffff" />
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="h2" fontWeight="bold" fontSize="1.2rem" color="white">
                      Total Participation
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    {campaignShivir?.total_participation ? (
                      <Typography variant="h1" fontWeight="bold" fontSize="1.5rem" color="white">
                        {formatIndianNumber(campaignShivir?.total_participation)}
                      </Typography>
                    ) : (
                      <Loader size={20} color="#ffffff" />
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
