import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React from 'react';
import Slider from 'react-slick';
import './testi.css';
import t11 from '../../assets/images/testimonialCar-image/t1i1.jpeg';
import t12 from '../../assets/images/testimonialCar-image/t1i2.jpeg';
import t21 from '../../assets/images/testimonialCar-image/t2i1.jpeg';
import t31 from '../../assets/images/testimonialCar-image/t3i1.jpeg';
import t32 from '../../assets/images/testimonialCar-image/t3i2.jpeg';
import t41 from '../../assets/images/testimonialCar-image/t4i1.jpeg';
import quotes from '../../assets/images/testimonialCar-image/Quotes.png';
import { Typography } from '@mui/material';

const testimonials = [
  // {
  //   name: 'Pankaj',
  //   photo: t11,
  //   state: 'Rajasthan',
  //   testimonial:
  //     'My daughter, Jivyanshi, has become so aware of cleanliness that she starts cleaning the house if it’s not done on time. If anyone throws trash in our neighborhood, she tells me with tears in her eyes to make sure it’s cleaned up. As her father, her passion inspires me every day.',
  // },
  {
    name: 'Subhas',
    photo: t21,
    state: 'Uttar Pradesh',
    testimonial:
      'The construction of toilets under the Swachh Bharat Mission has brought a real change in the homes of poor families. It’s not just about cleanliness, but also about protecting our families from communicable diseases. I am thankful to the government for this change.',
  },
  {
    name: 'Akhilesh Chauhan',
    photo: t31,
    state: 'Uttar Pradesh',
    testimonial:
      'Since the Swachh Bharat Mission started, Loni has transformed. The streets are cleaner, and open defecation is no longer a problem. We can now breathe in fresh air, free from the threat of diseases. My family and I proudly support this campaign.',
  },
  {
    name: 'Prakash Kumar Goyal',
    photo: t41,
    state: 'Rajasthan',
    testimonial:
      "The Swachh Bharat Mission has transformed our city and our lives. Streets are clean, people have toilets in home and with community and public toilets, people don't have to go in open and people don't dump garbage in open. Today, I am proud to live in a cleaner and healthier community ",
  },
];
const TestimonialCaraousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    centerMode: false,
    centerPadding: '60px',
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 200,
    autoplay: true,
    autoplaySpeed: 2000,
    focusOnSelect: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '40px',
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: '50px',
        },
      },
    ],
  };

  return (
    <>
      <Typography variant="h1" sx={{ color: '#3939A0', fontWeight: '700', textAlign: 'center', marginTop: "3%" }}>
        Citizen Testimonial
      </Typography>
      <div className="testimonial-carousel" style={{minHeight: "30vh", marginTop: "3%"}}>
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className="testimonial-card">
              <div className="card-content">
                <img className="person" src={testimonial.photo} alt={''} />
                <div className="text-content">
                  <div className="quote-parent">
                    <img className="quotes" src={quotes} alt="quotes" />
                  </div>
                  <p>{testimonial.testimonial}</p> 
                  <h4>{testimonial.name}</h4>
                  <h4>{testimonial.state}</h4>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default TestimonialCaraousel;
