import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ticker.css';

const Ticker = () => {
  const navigate = useNavigate();

  const handleTickerClick = () => {
    navigate('/citizen');
    // console.log("called")
  };

  return (
    <div className="ticker-container">
      <div className="scrolling-wrapper">
        <div className="scrolling-text" onClick={handleTickerClick}>
          Join SHS 2024 - Swabhav Swachhata Sanskaar Swachhata on MyGov! Participate in Many
          Exciting Competitions & Quizzes! Join SHS 2024 - Swabhav Swachhata Sanskaar Swachhata on
          MyGov! Participate in Many Exciting Competitions & Quizzes! Join SHS 2024 - Swabhav
          Swachhata Sanskaar Swachhata on MyGov! Participate in Many Exciting Competitions &
          Quizzes! Join SHS 2024 - Swabhav Swachhata Sanskaar Swachhata on MyGov! Participate in
          Many Exciting Competitions & Quizzes! Join SHS 2024 - Swabhav Swachhata Sanskaar Swachhata
          on MyGov! Participate in Many Exciting Competitions & Quizzes!
        </div>
      </div>
      <div className="static-text">All SHS 2024 events are 'Zero Waste Events'</div>
    </div>
  );
};

export default Ticker;
